import { Magic } from 'magic-sdk';
import getEnv from '../../env';
import { OAuthExtension } from '@magic-ext/oauth';
export var magic = function magic(options) {
  return new Magic(getEnv().MAGIC_LINK_KEY, options);
};
export var magicOauth = function magicOauth() {
  return new Magic(getEnv().MAGIC_LINK_KEY, {
    extensions: [new OAuthExtension()]
  });
};