export var MIN_WITHDRAW = 30;
export var MIN_TOPUP = 5;
export var MAX_TOPUP = 3000;
export var DEFAULT_OLD_MESSAGE_STEP = 15;
export var LOCAL_STORAGE = {
  AUTH_REDIRECT_URL: 'auth_redirect_url',
  COLLECTION_QUERY: 'collection_query',
  SAVE_SESSION_STORAGE: 'save_session_storage'
};
export var CUSTOM_MESSAGE_TYPE = {
  START_CONVENTION: 'start_convention'
};
export var PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export var prefix = 'artWallStreet';
export var emailTest = /(\W|^)[\w.+\-]*@magic\.link(\W|$)/g;
export var PARTNER_SIGN_UP_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfXMZ9LYBBT5wPaygUQg7mE7G4-UlMBcxojEGr94WS02msxAg/viewform';