import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from '../hooks/use-auth';
import { useRouter } from 'next/router';

var RedirectIfAuth = function RedirectIfAuth(_ref) {
  var children = _ref.children;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var router = useRouter();
  useEffect(function () {
    if (user) {
      router.push('/dashboard');
    }
  });
  return children;
};

export default RedirectIfAuth;