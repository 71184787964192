import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import LoadingSvg from './assets/loading.svg';
import PendingSvg from './assets/pending.svg';
import SuccessSvg from './assets/success.svg';
import FailSvg from './assets/fail.svg';
import RemoveSvg from './assets/remove.svg';
export var LoadingIcon = function LoadingIcon() {
  return _jsx(LoadingSvg, {
    className: "animate-spin -ml-1 mr-3 h-5 w-5"
  });
};
export var PendingIcon = function PendingIcon(_ref) {
  var classes = _ref.classes;
  return _jsx(PendingSvg, {
    className: classes
  });
};
export var SuccessIcon = function SuccessIcon(_ref2) {
  var classes = _ref2.classes;
  return _jsx(SuccessSvg, {
    className: classes
  });
};
export var FailIcon = function FailIcon(_ref3) {
  var classes = _ref3.classes;
  return _jsx(FailSvg, {
    className: classes
  });
};
export var RemoveIcon = function RemoveIcon(_ref4) {
  var classes = _ref4.classes;
  return _jsx(RemoveSvg, {
    className: classes
  });
};