import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import RedirectIfAuth from '@/containers/RedirectIfAuth';
import Login from '@/containers/Auth/Login';

var Home = function Home() {
  return _jsx(_Fragment, {
    children: _jsxs("div", {
      className: 'main-section',
      children: [_jsx("div", {
        className: "container xl:max-w-screen-xl mx-auto px-4 lg:pt-40 pt-24 lg:pb-20 pb-6 relative",
        children: _jsxs("div", {
          className: "lg:w-3/5 md:w-4/5 w-full",
          children: [_jsx("h1", {
            className: "new-text-color xl:text-6xl lg:text-5xl text-4xl xl:leading-tight lg:leading-tight leading-tight font-bold lg:mb-10 mb-5",
            children: "Welcome back!"
          }), _jsx("div", {
            className: "border-l-4 lg:pl-10 pl-5 border-yellow-new",
            children: _jsx("p", {
              className: "text-white xl:text-xl lg:text-lg text-base",
              children: "Login to your account to retrieve your NFTs."
            })
          })]
        })
      }), _jsx(RedirectIfAuth, {
        children: _jsx(Login, {})
      })]
    })
  });
};

export default Home;