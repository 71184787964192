import _defineProperty from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React from 'react';
import Home from '@/containers/Home/Home';
import Layout from '@/containers/Layout';
import { SeoModel } from 'lib/models/seo.model';

var Index = function Index() {
  var seoMeta = new SeoModel();
  return _jsx(Layout, _objectSpread(_objectSpread({}, seoMeta), {}, {
    fixedHeader: true,
    children: _jsx(Home, {})
  }));
};

export default Index;