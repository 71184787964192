import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';

var ErrorMessage = function ErrorMessage(_ref) {
  var name = _ref.name;
  return _jsx(FormikErrorMessage, {
    name: name,
    component: "div",
    className: "text-red-500"
  });
};

export default ErrorMessage;