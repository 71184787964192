import _toConsumableArray from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _classCallCheck from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import getEnv from 'env';
var env = getEnv();
export var SeoModel = function SeoModel(data) {
  var _data$openGraph, _data$openGraph2, _data$openGraph3, _openGraph$images;

  _classCallCheck(this, SeoModel);

  _defineProperty(this, "title", void 0);

  _defineProperty(this, "description", void 0);

  _defineProperty(this, "openGraph", void 0);

  this.title = (data === null || data === void 0 ? void 0 : data.title) || 'AWST | Your gateway into the NFT world';
  this.description = (data === null || data === void 0 ? void 0 : data.description) || 'Easy way to buy your first NFT, and track your portfolio of digital collectibles.';
  var openGraph = {
    title: (data === null || data === void 0 ? void 0 : (_data$openGraph = data.openGraph) === null || _data$openGraph === void 0 ? void 0 : _data$openGraph.title) || this.title,
    description: (data === null || data === void 0 ? void 0 : (_data$openGraph2 = data.openGraph) === null || _data$openGraph2 === void 0 ? void 0 : _data$openGraph2.description) || this.description,
    type: 'website',
    url: env.BASE_URL,
    site_name: 'AWST',
    // locale: 'vi-VN',
    defaultImageWidth: 400,
    defaultImageHeight: 300,
    images: [].concat(_toConsumableArray((data === null || data === void 0 ? void 0 : (_data$openGraph3 = data.openGraph) === null || _data$openGraph3 === void 0 ? void 0 : _data$openGraph3.images) || []), [{
      url: env.BASE_URL + '/awst-light-purple.png',
      alt: 'AWST | Your gateway into the NFT world',
      height: 300,
      width: 400
    }])
  };
  openGraph.images = (_openGraph$images = openGraph.images) === null || _openGraph$images === void 0 ? void 0 : _openGraph$images.filter(function (x) {
    return x.url;
  });
  this.openGraph = openGraph;
};